import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import MenuItem from '../menuItem'
import CloseIcon from '../../images/close.svg'
import ArrowIcon from '../../images/arrow-right-orange.svg'
import InnerWrapper from '../../elements/innerWrapper'
import { breakpoint, getColor } from '../../shared/theme'

const MenuWrapper = styled.div`
	background-color: ${getColor('black')};
	visibility: ${(props) => (props.menuOpen ? 'visible' : 'hidden')};
	opacity: ${(props) => (props.menuOpen ? '1' : '0')};
	pointer-events: ${(props) => (props.menuOpen ? 'all' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	padding: 30px 0;
	overflow-y: scroll;
	z-index: 50;

	@media ${breakpoint('tablet')} {
		padding: 46px 0;
	}

	transition: all ${(props) => props.theme.transitionSpeed.slow};
`

const MenuItemWrapper = styled.div`
	opacity: ${(props) => (props.menuOpen ? '1' : '0')};
	transform: ${(props) =>
		props.menuOpen ? 'translateX(0)' : 'translateX(-8px)'};
	display: flex;
	flex-direction: column;

	transition: all ${(props) => props.theme.transitionSpeed.slow};
	transition-delay: 300ms;
`

const CloseWrapper = styled.div`
	display: flex;
	justify-content: flex-end;

	@media ${breakpoint('tablet')} {
		display: flex;
		justify-content: flex-start;
	}
`

const Close = styled.img`
	margin-bottom: 10px;
	cursor: pointer;
	width: 20px;

	@media ${breakpoint('mobile')} {
		margin-bottom: 60px;
	}

	@media ${breakpoint('desktop')} {
		width: 32px;
	}

	&:hover {
		opacity: 0.5;
	}

	transition: opacity ${(props) => props.theme.transitionSpeed.default};
`

const SocialLinks = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: auto;

	@media ${breakpoint('mobile')} {
		display: none;
	}
`

const Link = styled.a`
	font-size: 10px;
	text-decoration: underline;
	text-transform: uppercase;
	color: ${getColor('white')};
	margin-bottom: 9px;

	&:last-child() {
		margin-bottom: 0;
	}
`

const SubmenuWrapper = styled.div`
	background-color: ${getColor('black')};
	visibility: ${(props) =>
		props.activeSubmenu == props.menuid && props.submenuOpen
			? 'visible'
			: 'hidden'};
	opacity: ${(props) =>
		props.activeSubmenu == props.menuid && props.submenuOpen ? '1' : '0'};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	padding: 30px 0;
	overflow-y: scroll;
	z-index: 50;

	@media ${breakpoint('tablet')} {
		padding: 46px 0;
	}

	transition: all ${(props) => props.theme.transitionSpeed.slow};
`

const MenuWrapperInner = styled.div`
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
`

const SubmenuInner = styled(MenuWrapperInner)``

const SubClose = styled(Close)`
	height: 20px;
	transform: rotate(180deg);
`

const Menu = ({ menuOpen, handleClick, currentLocation }) => {
	const [activeSubmenu, setActiveSubmenu] = useState(null)
	const [submenuOpen, toggleSubmenu] = useState(null)

	const handleSubClick = () => {
		toggleSubmenu(!submenuOpen)
	}

	useEffect(() => {
		toggleSubmenu(false)
	}, [currentLocation])

	return (
		<StaticQuery
			query={MENU_QUERY}
			render={(data) => {
				if (data.wpgraphql.menuItems) {
					const menuItems = data.wpgraphql.menuItems.nodes
					const wordPressUrl = data.wpgraphql.generalSettings.url

					return (
						<MenuWrapper menuOpen={menuOpen}>
							<InnerWrapper>
								<MenuWrapperInner>
									<CloseWrapper>
										<Close src={CloseIcon} alt="close" onClick={handleClick} />
									</CloseWrapper>
									<MenuItemWrapper menuOpen={menuOpen}>
										{menuItems &&
											menuItems.map((menuItem) => {
												return (
													<MenuItem
														key={menuItem.id}
														subId={menuItem.id}
														menuItem={menuItem}
														parent={menuItem.childItems.nodes.length > 0}
														wordPressUrl={wordPressUrl}
														activeSubmenu={(activeSubmenu) =>
															setActiveSubmenu(activeSubmenu)
														}
														submenuOpen={(submenuOpen) =>
															toggleSubmenu(submenuOpen)
														}
													/>
												)
											})}
									</MenuItemWrapper>
									<SocialLinks>
										<Link href="/more">Instagram</Link>
										<Link href="/about">YouTube</Link>
									</SocialLinks>

									{menuItems &&
										menuItems.map(({ childItems, id } = menuItem, index) => {
											return (
												<SubmenuWrapper
													key={index}
													activeSubmenu={activeSubmenu}
													menuid={id}
													submenuOpen={submenuOpen}
												>
													<InnerWrapper>
														<SubmenuInner>
															<CloseWrapper>
																<SubClose
																	src={CloseIcon}
																	alt="close"
																	onClick={handleSubClick}
																/>
															</CloseWrapper>
															{childItems.nodes.map((subMenuItem) => (
																<MenuItem
																	key={subMenuItem.id}
																	menuItem={subMenuItem}
																	wordPressUrl={wordPressUrl}
																/>
															))}
														</SubmenuInner>
													</InnerWrapper>
												</SubmenuWrapper>
											)
										})}
								</MenuWrapperInner>
							</InnerWrapper>
						</MenuWrapper>
					)
				}
			}}
		/>
	)
}

export default Menu

const MENU_QUERY = graphql`
	fragment MenuItem on WPGraphQL_MenuItem {
		connectedObject {
			__typename
		}
		id
		label
		url
		title
		target
	}

	query GETMAINMENU {
		wpgraphql {
			menuItems(where: { location: PRIMARY_NAVIGATION }, first: 20) {
				nodes {
					parentId
					...MenuItem
					childItems {
						nodes {
							title
							id
							label
							url
							title
							target
						}
					}
				}
			}
			generalSettings {
				url
			}
		}
	}
`
