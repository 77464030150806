import { Link } from 'gatsby'
import { FooterMenu } from '../footerMenu'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import { getColor, breakpoint } from '../../shared/theme'
import H5 from '../../elements/typography/h5'
import H3 from '../../elements/typography/h3'
import ContentContainer from '../contentContainer'

const FooterNav = styled.footer`
	padding: 28px 0 32px;

	a,
	button {
		transition: all 200ms ${(props) => props.theme.ease};

		&:hover {
			opacity: 0.6;
		}
	}

	@media ${breakpoint('tablet')} {
		padding: 97px 0 94px;
		border-top: 1px solid rgba(0, 0, 0, 0.2);
	}
`

const FooterLogo = styled.div`
	display: inline-flex;
	width: 55px;
	align-items: center;

	a {
		display: inline-flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	svg {
		width: 100%;
		height: 100%;
		transition: all 200ms ${(props) => props.theme.ease};
	}

	@media ${breakpoint('mobileSmall')} {
		width: 65px;
	}

	@media ${breakpoint('tablet')} {
		width: 100px;
	}

	@media ${breakpoint('laptop')} {
		width: 147px;
	}
`

const IconLink = styled.a`
	height: fit-content;
	width: fit-content;
	display: block;
`

const FooterIcon = styled.span`
	height: 20px;
	width: 20px;
	display: inline-block;
	margin-right: 16px;

	&:last-child {
		margin-right: 0;
	}

	svg {
		width: 100%;
		height: 100%;
	}

	@media ${breakpoint('tablet')} {
		height: 24px;
		width: 24px;
	}

	@media ${breakpoint('desktop')} {
		height: 30px;
		width: 30px;
	}
`

const FooterContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	@media ${breakpoint('mobile')} {
		gap: 20px;

		> div {
			flex-basis: calc(50% - 10px);
		}
	}

	@media ${breakpoint('tablet')} {
		gap: initial;

		> div {
			flex-basis: initial;
		}
	}
`

const FooterColumn = styled.div`
	display: flex;
	flex-direction: column;
	
	#footerForm {
		margin-top: 10px;
	}
`

const FooterColumnBottom = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 36px;

	@media ${breakpoint('mobile')} {
		margin-top: 0;
	}
`

const NewFooterRow = styled.div`
	display: flex;
	margin-top: auto;
    margin-bottom: 18px;

	@media ${breakpoint('tablet')} {
		margin-top: 32px;
	}
`

const FooterLink = styled.a`
	font-size: 10px;
	line-height: 20px;
	cursor: pointer;
	color: ${getColor('black')};

	&:hover {
		text-decoration: none;
		color: ${getColor('black')};
	}

	@media ${breakpoint('tablet')} {
		font-size: 14px;
		line-height: 28px;
	}

	@media ${breakpoint('laptop')} {
		font-size: 18px;
		line-height: 36px;
	}
`

export const FooterNew = ({ siteTitle }) => {
	const dateNow = new Date()
	const year = dateNow.getFullYear()

	useEffect(() => {
		const script = document.createElement('script')
		script.charset = 'utf-8'
		script.type = 'text/javascript'
		script.src = '//js.hsforms.net/forms/embed/v2.js'
		script.onload = () => {
			if (window.hbspt) {
				const form = window.hbspt.forms.create({
					region: 'na1',
					portalId: '44219446',
					formId: '1655ec43-8b86-491c-991f-4857cbb3b91c',
					target: '#footerForm'
				})
			}
		}

		document.body.appendChild(script)

		// Clean up the script tag on component unmount
		return () => {
			document.body.removeChild(script)
		}
	})

	return (
		<>
			<FooterNav>
				<ContentContainer>
					<FooterContentWrapper>
						<FooterColumn>
							<div id="footerForm"></div>
						</FooterColumn>
						<FooterColumn>
							<FooterLink href="/fitness">Multi-location fitness brands</FooterLink>
							<FooterLink href="/creators">Creators</FooterLink>
							<FooterLink href="/sustainability">Responsibility</FooterLink>
							<FooterLink href="/press">Resources</FooterLink>
							<NewFooterRow>
								<FooterIcon>
									<IconLink
										href="https://www.instagram.com/slyletica/?hl=en"
										title="Instagram"
										target="_blank"
									>
										<svg viewBox="0 0 24.7 24.7">
											<path d="M24.6,7.2c-0.2-4.5-2.7-7-7.2-7.2C16.1,0,15.7,0,12.3,0S8.6,0,7.2,0.1c-4.5,0.2-7,2.7-7.2,7.2C0,8.6,0,9,0,12.3 s0,3.8,0.1,5.1c0.2,4.5,2.7,7,7.2,7.2c1.3,0.1,1.7,0.1,5.1,0.1s3.8,0,5.1-0.1c4.5-0.2,7-2.7,7.2-7.2c0.1-1.3,0.1-1.7,0.1-5.1 S24.7,8.6,24.6,7.2z M12.3,19.3c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9s6.9,3.1,6.9,6.9C19.3,16.2,16.2,19.3,12.3,19.3z M20.6,5.4 c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3C21.4,2.7,22,3.3,22,4C22,4.8,21.4,5.4,20.6,5.4z" />
											<path d="M12.3,8.2c-2.3,0-4.1,1.8-4.1,4.1c0,2.3,1.8,4.1,4.1,4.1s4.1-1.8,4.1-4.1C16.4,10.1,14.6,8.2,12.3,8.2z" />
										</svg>
									</IconLink>
								</FooterIcon>
								<FooterIcon>
									<IconLink
										href="https://www.youtube.com/channel/UCRMCw63shT2wcJwQnSWFKIg"
										title="Youtube"
										target="_blank"
									>
										<svg
											version="1.1"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24.8 24.7"
										>
											<path d="M24.6,7.2c-0.2-4.5-2.7-7-7.2-7.2c-1.3,0-1.7,0-5.1,0S8.6,0,7.2,0.1C2.7,0.3,0.2,2.8,0,7.3c0,1.3,0,1.7,0,5s0,3.8,0.1,5.1 c0.2,4.5,2.7,7,7.2,7.2c1.3,0.1,1.7,0.1,5.1,0.1c3.4,0,3.8,0,5.1-0.1c4.5-0.2,7-2.7,7.2-7.2c0.1-1.3,0.1-1.7,0.1-5.1 C24.8,8.9,24.7,8.6,24.6,7.2z M17.9,12.5l-8.6,5c-0.1,0.1-0.3,0-0.3-0.2V7.4c0-0.2,0.2-0.3,0.3-0.2l8.6,5C18,12.3,18,12.4,17.9,12.5 z" />
										</svg>
									</IconLink>
								</FooterIcon>
								<FooterIcon>
									<IconLink
										href="https://www.linkedin.com/company/slyletica/"
										title="LinkedIn"
										target="_blank"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="64"
											height="64"
											viewBox="0 0 256 256"
										>
											<path
												strokeMiterlimit="10"
												d="M48 8H16a8 8 0 00-8 8v32a8 8 0 008 8h32a8 8 0 008-8V16a8 8 0 00-8-8zM24 47h-5V27h5zm.029-23.991c-.647.66-1.491.991-2.529.991-1.026 0-1.865-.341-2.519-1.023-.654-.682-.981-1.519-.981-2.509 0-1.02.327-1.852.981-2.498.654-.647 1.493-.97 2.519-.97 1.038 0 1.882.323 2.529.969.647.646.971 1.479.971 2.499 0 1.034-.324 1.881-.971 2.541zM47 47h-5V35.887C42 32.788 40.214 31 38 31c-1.067 0-2.274.648-2.965 1.469-.691.821-1.035 1.862-1.035 3.125V47h-5V27h5v3.164h.078c1.472-2.435 3.613-3.644 6.426-3.652C44.5 26.5 47 29.5 47 34.754z"
												fontFamily="none"
												fontSize="none"
												fontWeight="none"
												textAnchor="none"
												transform="matrix(1.33 0 0 1.33 -42.24 -42.24) scale(4)"
											></path>
										</svg>
									</IconLink>
								</FooterIcon>
							</NewFooterRow>
						</FooterColumn>

						<FooterColumn>
							<FooterLink href="/about">About us</FooterLink>
							<FooterLink href="/careers">Careers</FooterLink>
							<FooterLink href="/terms">Terms</FooterLink>
							<FooterLink href="/privacy-policy">Privacy policy</FooterLink>
							<FooterLink href="/contact">Contact</FooterLink>
						</FooterColumn>

						<FooterColumnBottom>
							<FooterLogo>
								<Link to="/" title={siteTitle}>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 60.9">
										<path d="M56.9 50.1h-2.2l-4.1 10.5h2.1l1.1-2.9h4l1.1 2.9H61L56.9 50.1zM54.4 55.9l1.4-3.7 1.3 3.7H54.4zM35.1 57.6h-2.1c-0.5 1-1.4 1.4-2.5 1.4 -2.1 0-3.2-1.8-3.2-3.7 0-1.9 1.1-3.7 3.2-3.7 1.1 0 2 0.4 2.5 1.4h2.1c-0.3-1-0.5-1.4-1.4-2.1 -0.9-0.7-2-1.1-3.2-1.1 -3.1 0-5.2 2.5-5.2 5.5 0 2.9 2.2 5.5 5.2 5.5 1.2 0 2.3-0.4 3.2-1.1C34.5 59.1 34.8 58.7 35.1 57.6L35.1 57.6zM9.1 60.6v-1.7H5.5v-7h3.5v-1.7H0v1.7h3.5v7H0v1.7L9.1 60.6zM0.5 25.2v10.5h8.1v-1.8H2.5v-8.7H0.5zM26.1 25.2v10.5h8.3v-1.8H28v-2.6h6.3v-1.8H28V27h6.3v-1.8H26.1zM51 25.2V27h3.8v8.7h2V27h3.8v-1.8H51zM60.9 0.3h-2.3L55.8 5 53 0.3h-2.3l4.1 6.6v3.9h2V6.9L60.9 0.3zM34.3 10.7V8.9h-6.2V0.3h-2v10.5H34.3zM8.6 7.7c0-0.9-0.4-1.9-1.2-2.4 -0.8-0.6-2-0.8-2.9-1.1C3.8 4.1 2.8 3.8 2.8 3c0-0.8 0.9-1.1 1.6-1.1C5.2 1.8 6 2.1 6.2 3h2C8.1 0.9 6.5 0 4.5 0 2.6 0 0.8 0.9 0.8 3c0 2.5 2.5 2.9 4.4 3.4 0.7 0.2 1.4 0.5 1.4 1.3 0 1.1-1.2 1.4-2.1 1.4 -1.1 0-1.9-0.3-2.1-1.5h-2c0 0.8 0.1 1.3 0.6 2 0.8 1 2.2 1.4 3.5 1.4C6.5 11 8.6 10 8.6 7.7" />
									</svg>
								</Link>
							</FooterLogo>
						</FooterColumnBottom>
					</FooterContentWrapper>
				</ContentContainer>
			</FooterNav>
		</>
	)
}
