import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

const Logo = styled.div`
	display: inline-flex;
	width: 50px;
	align-items: center;

	a {
		display: inline-flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	svg {
		width: 100%;
		height: 100%;
		transition: all 200ms ${(props) => props.theme.ease};
	}

	@media ${breakpoint('tablet')} {
		width: 60px;
	}

	${(props) =>
		props.currentPage === 'home' &&
		css`
			svg {
				fill: ${getColor('black')};
			}

			@media ${breakpoint('tablet')} {
				svg {
					fill: ${getColor('white')};
				}
			}
		`}

	${(props) =>
		props.scrolled &&
		css`
			svg {
				fill: ${getColor('black')};
			}
		`}
`

export const HeaderLogo = ({ currentPage, scrolled, siteTitle }) => {
	return (
		<Logo currentPage={currentPage} scrolled={scrolled}>
			<Link to="/" title={siteTitle}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 60.9">
					<path d="M56.9 50.1h-2.2l-4.1 10.5h2.1l1.1-2.9h4l1.1 2.9H61L56.9 50.1zM54.4 55.9l1.4-3.7 1.3 3.7H54.4zM35.1 57.6h-2.1c-0.5 1-1.4 1.4-2.5 1.4 -2.1 0-3.2-1.8-3.2-3.7 0-1.9 1.1-3.7 3.2-3.7 1.1 0 2 0.4 2.5 1.4h2.1c-0.3-1-0.5-1.4-1.4-2.1 -0.9-0.7-2-1.1-3.2-1.1 -3.1 0-5.2 2.5-5.2 5.5 0 2.9 2.2 5.5 5.2 5.5 1.2 0 2.3-0.4 3.2-1.1C34.5 59.1 34.8 58.7 35.1 57.6L35.1 57.6zM9.1 60.6v-1.7H5.5v-7h3.5v-1.7H0v1.7h3.5v7H0v1.7L9.1 60.6zM0.5 25.2v10.5h8.1v-1.8H2.5v-8.7H0.5zM26.1 25.2v10.5h8.3v-1.8H28v-2.6h6.3v-1.8H28V27h6.3v-1.8H26.1zM51 25.2V27h3.8v8.7h2V27h3.8v-1.8H51zM60.9 0.3h-2.3L55.8 5 53 0.3h-2.3l4.1 6.6v3.9h2V6.9L60.9 0.3zM34.3 10.7V8.9h-6.2V0.3h-2v10.5H34.3zM8.6 7.7c0-0.9-0.4-1.9-1.2-2.4 -0.8-0.6-2-0.8-2.9-1.1C3.8 4.1 2.8 3.8 2.8 3c0-0.8 0.9-1.1 1.6-1.1C5.2 1.8 6 2.1 6.2 3h2C8.1 0.9 6.5 0 4.5 0 2.6 0 0.8 0.9 0.8 3c0 2.5 2.5 2.9 4.4 3.4 0.7 0.2 1.4 0.5 1.4 1.3 0 1.1-1.2 1.4-2.1 1.4 -1.1 0-1.9-0.3-2.1-1.5h-2c0 0.8 0.1 1.3 0.6 2 0.8 1 2.2 1.4 3.5 1.4C6.5 11 8.6 10 8.6 7.7" />
				</svg>
			</Link>
		</Logo>
	)
}

Logo.propTypes = {
	currentPage: PropTypes.string,
	siteTitle: PropTypes.string,
}

Logo.defaultProps = {
	currentPage: ``,
	siteTitle: ``,
}
