import './typography.scss'

export const Theme = {
	name: 'Slyletica Theme',
	colours: {
		black: '#000',
		white: '#fff',
		greyLight: '#F9F9F9',
		greyMid: '#C4C4C4',
		orange: '#FF4D10',
		brown: '#D7A373',
		purple: '#C1BDDB',
		green: '#224736',
		blue: '#88D3D8',
		beige: '#D2C7BA',
		orangeProgress: '#FF5E03',
		greyBg: '#F1F2F2',
	},
	fonts: {
		goodSans: 'GoodSans',
	},
	mediaBreakpoints: {
		desktop: '(min-width: 1539px)',
		laptop: '(min-width: 1279px)',
		tablet: '(min-width: 889px)',
		mobile: '(min-width: 539px)',
		mobileSmall: '(min-width: 379px)',
	},
	layout: {
		maxContainerWidth: '1440px',
	},
	transitionSpeed: {
		default: '.2s',
		fast: '.2s',
		slow: '.6s',
	},
	ease: 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
}

export const breakpoint = (size) => (props) =>
	props.theme.mediaBreakpoints[size]

export const getColor = (colour) => (props) => props.theme.colours[colour]

export const ease = () => (props) => props.theme.ease
