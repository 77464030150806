import { createGlobalStyle } from 'styled-components'
import { Theme as theme } from './theme'

const GlobalStyle = createGlobalStyle`
  body, html {
      height: 100%;
      scroll-behavior: smooth;
  }

	*
	{
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		box-sizing: border-box;
	}

  a, button { cursor: pointer }

  html, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font-weight: normal;
      vertical-align: baseline;
  }

  main, article, aside, figure, footer, header, nav, section, details, summary {display: block;}

  html {
      box-sizing: border-box;
  }

  button,
  input,
  optgroup,
  select,
  textarea
  {
  	font-family: inherit; /* 1 */
  	font-size: 100%; /* 1 */
  	line-height: 1.15; /* 1 */
  	margin: 0; /* 2 */
  	outline: none;
  	border: none;
  	background: none;
  	appearance: none;
  	padding: 0;
  	-webkit-appearance: none;
  	color: inherit;
  	cursor: pointer;

  	&:focus
  	{
  		outline: none;
  	}
  }

  /**
   * Remove the inner border and padding in Firefox.
   */
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner
  {
  	border-style: none;
  	padding: 0;
  }

  /**
   * Restore the focus styles unset by the previous rule.
   */
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring
  {
  	outline: 1px dotted ButtonText;
  }

  /**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
  button,
  input
  {
  	overflow: visible;
  }

  /**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
  button,
  select
  { /* 1 */
  	text-transform: none;
  }

  /**
   * Correct the inability to style clickable types in iOS and Safari.
   */

  button,
  [type="button"],
  [type="reset"],
  [type="submit"]
  {
  	-webkit-appearance: button;
  }

  [type="checkbox"],
  [type="radio"]
  {
  	box-sizing: border-box; /* 1 */
  	padding: 0; /* 2 */
  }

  /**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button
  {
  	height: auto;
  }

  /**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */

  [type="search"]
  {
  	-webkit-appearance: textfield; /* 1 */
  	outline-offset: -2px; /* 2 */
  }

  /**
   * Remove the inner padding in Chrome and Safari on macOS.
   */

  [type="search"]::-webkit-search-decoration
  {
  	-webkit-appearance: none;
  }


  ::-webkit-file-upload-button
  {
  	-webkit-appearance: button; /* 1 */
  	font: inherit; /* 2 */
  }

  details
  {
  	display: block;
  }

  /*
   * Add the correct display in all browsers.
   */

  summary
  {
  	display: list-item;
  }

  template
  {
  	display: none;
  }

  [hidden]
  {
  	display: none;
  }

  ul,
  ol
  {
  	list-style: none;
  	padding: 0;
  	margin: 0;

  	li
  	{
  		padding: 0;
  		margin: 0;
  	}
  }

  *,
  *:before,
  *:after {
      box-sizing: inherit;
	}

  a {
			text-decoration: none;
			color: inherit;
  }

  html
  {
    /* stylelint-disable */
  	scrollbar-color: ${theme.colours.black} transparent;
  	scrollbar-width: thin;
  	/* stylelint-enable */
  }
  
  body {
    margin: 0;
    font-family: '${theme.fonts.goodSans}';
    -webkit-font-smoothing: antialiased;
    

    ::-webkit-scrollbar
  	{
  		width: 12px;
  	}

  	::-webkit-scrollbar-thumb
  	{
  		background: ${theme.colours.black};
      border: 4px solid ${theme.colours.white};
  	}

  	::-webkit-scrollbar-track
  	{
  		background: transparent;
  	}
  }

  h1.header
  {
    font-size: 42px;
    line-height: 1.07;

    @media ${theme.mediaBreakpoints.laptop} {
      font-size: 68px;
			line-height: 1.01;
    }

    @media ${theme.mediaBreakpoints.desktop} {
      font-size: 98px;
    }
  }

  .featured-text,
  blockquote
  {
    font-size: 20px;
    line-height: 1.55;
    letter-spacing: -1px;

    @media ${theme.mediaBreakpoints.tablet} {
      font-size: 32px;
      line-height: 1.26;
    }

    @media ${theme.mediaBreakpoints.desktop} {
      font-size: 36px;
    }
  }

  h1
  {
    font-size: 24px;
    line-height: 1.3;

    @media ${theme.mediaBreakpoints.tablet} {
      font-size: 36px;
    }

    @media ${theme.mediaBreakpoints.desktop} {
      font-size: 44px;
    }
  }

  h2
  {
    font-size: 24px;
    line-height: 1.36;
    letter-spacing: -0.5px;

    @media ${theme.mediaBreakpoints.tablet} {
      font-size: 28px;
    }

    @media ${theme.mediaBreakpoints.desktop} {
      font-size: 36px;
    }
  }

  h3
  {
    font-size: 20px;
    line-height: 1.42;
    letter-spacing: -1px;

    @media ${theme.mediaBreakpoints.tablet} {
      font-size: 24px;
    }

    @media ${theme.mediaBreakpoints.desktop} {
      font-size: 28px;
    }
  }

  h4
  {
    font-size: 20px;
    line-height: 1.4;

    @media ${theme.mediaBreakpoints.laptop} {
      font-size: 25px;
      line-height: 1.28;
    }
  }

  h5
  {
    font-size: 10px;
    line-height: 1.42em;

    @media ${theme.mediaBreakpoints.tablet} {
      font-size: 12px;
    }

    @media ${theme.mediaBreakpoints.laptop} {
      font-size: 14px;
    }
  }

  h6 {
    font-size: 8px;
    line-height: 1.66;

    @media ${theme.mediaBreakpoints.tablet} {
      font-size: 10px;
    }

    @media ${theme.mediaBreakpoints.laptop} {
      font-size: 12px;
    }
  }

  p,
  body,
	ul,
	ol
  {
    font-size: 14px;
    line-height: 1.7;

    @media ${theme.mediaBreakpoints.mobile} {
      font-size: 16px;
    }
    
		@media ${theme.mediaBreakpoints.tablet} {
      line-height: 1.4;
    }

    @media ${theme.mediaBreakpoints.desktop} {
      font-size: 18px;
    }
  }

  ::selection {
      background: ${theme.colours.orange};
  }
  ::-moz-selection {
      background: ${theme.colours.orange};
  }

  .bg-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .mobile-hide {
    display: none !important;

		&--flex {
			display: none !important;

			@media ${theme.mediaBreakpoints.tablet} {
				display: flex !important;
			}
		}

    @media ${theme.mediaBreakpoints.tablet} {
      display: block !important;
    }
  }

  .mobile-show {
    display: block !important;

		&--flex {
			display: flex !important;

			@media ${theme.mediaBreakpoints.tablet} {
				display: none !important;
			}
		}

    @media ${theme.mediaBreakpoints.tablet} {
      display: none !important;
    }
  }

  .worksection-swiper-slide {
		display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
	  h3 {
		  margin-bottom: 14px;

		  @media ${theme.mediaBreakpoints.tablet} {
			  margin-bottom: 28px;
		  }
	  }

	  p {
		  margin-bottom: 20px;
	  }
  }

	.fade-enter,
	.fade-done {
		opacity: 0;
	}

	.fade-enter-active {
		opacity: 1;
		transition: opacity 500ms;
	}

	.fade-enter-done {
		opacity: 1;
	}

	.fade-exit-active {
		opacity: 0;
		transition: opacity 500ms;
	}

	canvas {
		position: fixed;
		z-index: -1; // put it to background
		left: 0; // position it to fill the whole screen
		top: 0; // position it to fill the whole screen
		transition: opacity 200ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
	}

	.dom-gl.hidden
	{
		opacity: 0;
	}

	iframe
	{
		max-width: 100%;
	}
`
export default GlobalStyle
