export const CreateLocalLink = (menuItem, wordPressUrl, blogUri = '/more') => {
	const { url, connectedObject } = menuItem

	//Remove the trailing /wp from the WP url for Bedrock setups
	wordPressUrl = wordPressUrl.replace('/wp', '')

	if (url === '#') {
		return null
	}

	//Change the new URL to a local one
	let newUri = url.replace(wordPressUrl, '')

	//For blog URLs add in the blog prefix
	if (connectedObject && connectedObject.__typename === 'WPGraphQL_Post') {
		newUri = blogUri + newUri
	}

	return newUri
}
