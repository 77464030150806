import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../shared/theme'

const Wrapper = styled.div`
	width: 85%;
	margin: 0 auto;
	max-width: 1190px;
	position: relative;

	@media ${breakpoint('mobile')} {
		width: 82.6%;
	}
`

export const ContentContainer = ({ children, style }) => {
	return <Wrapper style={{ ...style }}>{children}</Wrapper>
}

export default ContentContainer
