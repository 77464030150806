import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { breakpoint } from '../../shared/theme'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FooterMenuItem from '../../components/footerMenuItem'

const Nav = styled.ul`
	display: flex;
	flex-wrap: wrap;
	visibility: collapse;
	visibility: ${(props) =>
		props.breakpoint == 'mobile' ? 'visible' : 'collapse'};
	justify-content: space-between;
	width: ${() => {
		return `calc(${(4 / 6) * 100}% - 8px)`
	}};
	font-size: 10px;
	text-transform: uppercase;

	@media ${breakpoint('mobile')} {
		font-size: 12px;
		width: ${() => {
			return `calc(${(3 / 6) * 100}% - 8px)`
		}};
	}
	@media ${breakpoint('tablet')} {
		text-transform: none;
		width: auto;
		justify-content: initial;
		visibility: ${(props) =>
			props.breakpoint == 'mobile' ? 'collapse' : 'visible'};
	}
`

const FOOTER_MENU_QUERY = graphql`
	fragment MenuItem on WPGraphQL_MenuItem {
		connectedObject {
			__typename
		}
		id
		label
		url
		title
		target
	}

	query GETFOOTERMENU {
		wpgraphql {
			menuItems(where: { location: SECONDARY_NAVIGATION }) {
				nodes {
					...MenuItem
				}
			}
			generalSettings {
				url
			}
		}
	}
`

export const FooterMenu = ({ breakpoint }) => {
	const data = useStaticQuery(FOOTER_MENU_QUERY)
	const menuItems = data.wpgraphql.menuItems.nodes
	const wordPressUrl = data.wpgraphql.generalSettings.url

	return (
		<Nav breakpoint={breakpoint}>
			{menuItems &&
				menuItems.map((menuItem) => (
					<FooterMenuItem
						key={menuItem.id}
						menuItem={menuItem}
						wordPressUrl={wordPressUrl}
					/>
				))}
		</Nav>
	)
}

FooterMenu.propTypes = {
	breakpoint: PropTypes.string,
}

FooterMenu.defaultProps = {
	breakpoint: '',
}
