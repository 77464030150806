import React from 'react'
import styled from 'styled-components'
import { CreateLocalLink } from '../../utils'
import { Link } from 'gatsby'
import { breakpoint } from '../../shared/theme'

const MenuItem = styled.li`
	width: 100%;
	margin-bottom: 6px;

	a {
		letter-spacing: 0.25px;

		@media ${breakpoint('tablet')} {
			letter-spacing: none;
		}
	}

	@media ${breakpoint('mobileSmall')} {
		width: calc(50% - 8px);
	}

	@media ${breakpoint('tablet')} {
		width: auto;
		margin-bottom: 0;
		margin-right: 16px;
		text-decoration: underline;
	}

	@media ${breakpoint('desktop')} {
		margin-right: 60px;
	}

	&:last-child {
		margin-right: 0;
	}
`

const FooterMenuItem = ({ menuItem, wordPressUrl }) => {
	const { label, url, id } = menuItem
	return (
		<MenuItem>
			<Link to={CreateLocalLink(menuItem, wordPressUrl)} id={id}>
				{label}
			</Link>
		</MenuItem>
	)
}

export default FooterMenuItem
