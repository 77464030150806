import React, { useEffect } from 'react'
import Gl from '../../components/morePosts/gl';
import { SwitchTransition, CSSTransition } from 'react-transition-group'

const timeout = 200
const getTransitionStyles = {
	entering: {
		opacity: 0,
	},
	entered: {
		transition: `opacity ${timeout}ms ease-in-out`,
		opacity: 1,
	},
	exiting: {
		transition: `opacity ${timeout}ms ease-in-out`,
		opacity: 0,
	},
}

const Transition = ({ children, location }) => {
	
	const test = {
		pathname: '/',
	}

	const currentLocation = location || test
	let slug = currentLocation.pathname == '/' ? 'home' : currentLocation.pathname

	useEffect(() => {
		const canvas = document.querySelector('.dom-gl');

		if (currentLocation.pathname !== '/more/')
		{
			while (Gl.scene.children.length > 0)
			{
				Gl.scene.remove(Gl.scene.children[0])
			}

			canvas.classList.add('hidden');
		}
		else {
			canvas.classList.remove('hidden');
		}
	}, [currentLocation])

	return (
		<SwitchTransition mode={'out-in'}>
			<CSSTransition
				timeout={400}
				key={slug}
				classNames="fade"
			>
				{children}
			</CSSTransition>
		</SwitchTransition>
	)
}

export default Transition
