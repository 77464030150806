import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;

	@media ${(props) => props.theme.mediaBreakpoints.mobile} {
		padding-left: 32px;
		padding-right: 32px;
	}

	@media ${(props) => props.theme.mediaBreakpoints.desktop} {
		padding-left: 44px;
		padding-right: 44px;
	}
`

const InnerWrapper = (props) => {
	return <Wrapper>{props.children}</Wrapper>
}

export default InnerWrapper
export { Wrapper }
