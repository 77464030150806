import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
const UniversalLink = ({
	children,
	to,
	activeClassName,
	partiallyActive,
	...other
}) => {
	//Checks if the url starts with a single slash, if so it is an internal link, otherwise external
	const internal = /^\/(?!\/)/.test(to)
	if (internal) {
		//Internal link, use Gatsby's link component
		return (
			<GatsbyLink
				to={to}
				activeClassName={activeClassName}
				partiallyActive={partiallyActive}
				{...other}
			>
				{children}
			</GatsbyLink>
		)
	}

	//Not an internal link, use an a tag instead
	return (
		<a href={to} {...other} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	)
}

export default UniversalLink
